<template>
	<header>
		<div class="modale" id="nouvelle_offre" ref="nouvelle_offre" v-if="$store.state.machines_ready">
			<div class="close" @click="closeOffer">
				<i class="fal fa-times"></i>
			</div>
			<h1>Create new offer</h1>
			<p>
				You are about creating a new offer.
				<br />This action require four steps.
			</p>

			<div class="line">
				<div class="double">
					<label for="clients_list_input">First, select a customer</label>
					<div class="adhoc" @click="$router.push('/customers/')">or create a new customer</div>
				</div>

				<i class="fal fa-caret-down"></i>
				<input type="text" list="clients_list" @change="updateClient" id="clients_list_input" />
				<datalist id="clients_list">
					<option v-for="item in $store.state.clients" :key="item.id" :data-id="item.id" :value="item.fields.nom"></option>
				</datalist>
			</div>

			<div class="line" v-if="step1">
				<label for="choosemachine">Then, select a machine</label>
				<i class="fal fa-caret-down"></i>

				<input type="text" list="choosemachine_list" @change="updateMachine" id="choosemachine" />
				<datalist id="choosemachine_list">
					<option v-for="machine in $store.state.machines" :key="machine.id" :data-id="machine.id" :value="machine.fields.name"></option>
				</datalist>
			</div>

			<div class="line" v-if="step2">
				<label for="choosetheme">Good ! Now, select the main theme</label>
				<i class="fal fa-caret-down"></i>
				<select id="choosetheme" v-model="theme" @change="step3 = true">
					<option label v-for="item in $store.state.images" :key="item.id" :value="item.id">{{ item.fields.Name }}</option>
				</select>
			</div>

			<div v-if="$store.state.user.role.administrator && step3" class="line">
				<label for="chooseuser">Create offer for </label>
				<i class="fal fa-caret-down"></i>

				<input type="text" list="chooseuser_list" @change="udapteCommercial" id="chooseuser" />
				<datalist id="chooseuser_list">
					<option
						v-for="commercial in commerciaux"
						:key="commercial.ID"
						:value="`${commercial.display_name} - ${commercial.user_nicename}`"
					></option>
				</datalist>
			</div>

			<div class="line" v-if="step3">
				<label for="chooseunit">Then, select the unit system of your offer</label>
				<i class="fal fa-caret-down"></i>
				<select id="chooseunit" v-model="unit">
					<option value="metre">Metrics</option>
					<option value="pouces">US</option>
				</select>
			</div>

			<div class="line" v-if="step3">
				<label for="chooseunit">Then, select the currency system of your offer</label>
				<i class="fal fa-caret-down"></i>
				<select v-model="devise">
					<option value="EUR">Euro</option>
					<option value="USD">US Dollard</option>
					<option value="CHF">Swiss Franc</option>
					<option value="CAD">Canadian Dollar</option>
					<option value="AUD">Australian Dollar</option>
					<option value="PLN">Polish Zloty</option>
					<option value="GBP">British Pound Sterling</option>
					<option value="JPY">Japanese Yen</option>
					<option value="DKK">Danish Krone</option>
					<option value="SEK">Swedish Krona</option>
					<option value="THB">Thai Baht</option>
					<option value="NZD">New Zealand Dollar</option>
				</select>
			</div>

			<div class="line" v-if="step3">
				<label for="choosetheme">Finally, select the language of your offer</label>
				<i class="fal fa-caret-down"></i>
				<select id="choosetheme" v-model="langue">
					<option value="en">English</option>
					<option value="fr">Français</option>
					<option value="de">Deutsch</option>
					<option value="pl">Polskie</option>
				</select>
			</div>

			<!-- <div v-if="$store.state.user.role.administrator && step3" class="line">
				<label for="chooseprices">Prices to apply</label>
				<i class="fal fa-caret-down"></i>
				<select id="chooseprices" ref="chooseprices" v-model="gamme_de_prix">
					<option v-for="price in prices" :key="price.id" :value="price.id">{{ price.fields.Name | capitalize }}</option>
				</select>
			</div> -->

			<div v-if="step3" class="line">
				<label for="chooseprices">Prices to apply</label>
				<i class="fal fa-caret-down"></i>
				<select id="chooseprices" ref="chooseprices" v-model="gamme_de_prix">
					<option v-for="price in prices" :key="price.id" :value="price.id">{{ price.fields.Name | capitalize }}</option>
				</select>
			</div>

			<!-- <div v-if="step3 && !$store.state.user.role.administrator" class="line">
				<label for="chooseprices">Prices to apply</label>
				<i class="fal fa-caret-down"></i>
				<select id="chooseprices" ref="chooseprices" v-model="gamme_de_prix">
					<option v-for="price in prices" :key="price.id" :value="price.id">{{ price.fields.Name | capitalize }}</option>
				</select>
			</div> -->

			<div class="line" v-if="step3">
				<div class="confirmation">
					<button @click="saveOffer()">Create offer</button>
				</div>
			</div>
		</div>
		<Navigation :user="user"/>
	</header>
</template>
<script>
import Airtable from "airtable";
import moment from "moment";
import Navigation from "../components/Navigation.vue";
var base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appsk0eykwa6zk25e");
export default {
	name: "Header",
	components: { Navigation },
	data() {
		return {
			name_new_offer: "",
			type_machine: "",
			client: "",
			theme: "",
			devise: "EUR",
			unit: "metre",
			langue: "en",
			user_id: this.$store.state.user.ID,
			step1: false,
			step2: false,
			step3: false,
			step4: false,
			step5: false,
		};
	},
	computed: {
		gamme_de_prix: {
			get() {
				return this.$store.state.user.custom_fields.gamme_de_prix[0];
			},
			set(newVal) {
				return newVal;
			},
		},
		user() {
			return this.$store.state.user;
		},
		prices() {
			if (this.$store.state.user.role.administrator) {
				return this.$store.state.prices;
			} else {
				let temp = [];
				this.$store.state.prices.forEach((el) => {
					if (this.$store.state.user.custom_fields.gamme_de_prix.includes(el.id)) {
						temp.push(el);
					}
				});
				return temp;
			}
		},
		commerciaux() {
			return this.$store.state.commerciaux;
		},
	},
	methods: {
		udapteCommercial(e) {
			let selected_m = this.commerciaux.find((el) => {
				let concat_name = `${el.display_name} - ${el.user_nicename}`;
				return concat_name === e.target.value;
			});
			selected_m && (this.user_id = selected_m.ID);
		},
		updateMachine(e) {
			let selected_machine = this.$store.state.machines.find((el) => el.fields.name === e.target.value);
			selected_machine && (this.type_machine = selected_machine.id) && (this.step2 = true);
		},
		updateClient(e) {
			let selected_client = this.$store.state.clients.find((el) => el.fields.nom === e.target.value);
			selected_client && (this.client = selected_client.id) && (this.step1 = true);
		},
		createOffer() {
			this.$refs.nouvelle_offre.classList.add("visible");
		},
		closeOffer() {
			this.$refs.nouvelle_offre.classList.remove("visible");
		},
		closeClient() {
			this.$refs.nouveau_client.classList.remove("visible");
		},
		saveOffer() {
			this.$store.commit("setFreeze", {
				affichage: true,
				texte: "",
			});
			this.$refs.nouvelle_offre.classList.remove("visible");
			// Allez, on créé les textes
			var date_formatee;
			var page2_intitule = "";
			var page2_demande = "Enter text here / Insérer votre texte ici";
			if (this.$store.state.user.role.administrator) {
				var nom_commercial = "";
				var nom_societe = "";
				var page2_contact_commercial = "";
				this.commerciaux.forEach((commercial) => {
					if (commercial.ID == this.user_id) {
						nom_commercial = commercial.display_name;
						nom_societe = commercial.custom_fields.societe;
						page2_contact_commercial = `<h3>${commercial.custom_fields.societe}</h3><p>${commercial.custom_fields.adresse}<br />Tel. ${commercial.custom_fields.numero_de_telephone}<br />Email. ${commercial.user_email}</p>`;
					}
				});

				base("offres").create(
					[
						{
							fields: {
								id_offre: moment().format("YYYYMMDD") + "-" + "WEB" + moment().format("hmm"),
								id_commercial: parseInt(this.user_id),
								client: [this.client],
								machine: [this.type_machine],
								theme: [this.theme],
								langue: this.langue,
								statut: "draft",
								devise: this.devise,
								unite: this.unit,
								sous_total_machines: 0,
								sous_total_controls: 0,
								sous_total_accessoires: 0,
								total: 0,
								gamme_de_prix: [this.$refs.chooseprices.value],
								page2_contact_commercial: page2_contact_commercial,
								page2_demande: page2_demande,
								page2_intitule: "-",
								nom_commercial: nom_commercial,
								nom_societe: nom_societe,
							},
						},
					],
					(err, records) => {
						if (err) {
							console.log(err);
						}
						this.step2 = false;
						this.step1 = false;
						this.step3 = false;
						this.step4 = false;
						this.step5 = false;
						this.name_new_offer = "";
						this.client = "";
						this.theme = "";
						this.langue = "";
						this.unit = "metre";
						this.$store.dispatch("listeOffres").then(() => {
							this.$store.commit("setFreeze", {
								affichage: false,
								texte: "",
							});
							this.$router.push("/offer/" + records[0].id + "/" + records[0].fields.machine_name[0]);
						});
					}
				);
			} else {
				var page2_contact_commercial = `<h3>${this.$store.state.user.custom_fields.societe}</h3><p>${this.$store.state.user.custom_fields.adresse}<br />Tel. ${this.$store.state.user.custom_fields.numero_de_telephone}<br />Email. ${this.$store.state.user.user_email}</p>`;
				base("offres").create(
					[
						{
							fields: {
								id_offre: moment().format("YYYYMMDD") + "-" + "WEB" + moment().format("hmm"),
								id_commercial: parseInt(this.$store.state.user.ID),
								client: [this.client],
								machine: [this.type_machine],
								theme: [this.theme],
								langue: this.langue,
								statut: "draft",
								devise: this.devise,
								sous_total_machines: 0,
								sous_total_controls: 0,
								sous_total_accessoires: 0,
								total: 0,
								unite: this.unit,
								gamme_de_prix: [this.$refs.chooseprices.value],
								page2_contact_commercial: page2_contact_commercial,
								page2_demande: page2_demande,
								nom_commercial: this.$store.state.user.display_name,
							},
						},
					],
					(err, records) => {
						this.step2 = false;
						this.step1 = false;
						this.step3 = false;
						this.step4 = false;
						this.step5 = false;
						this.name_new_offer = "";
						this.client = "";
						this.theme = "";
						this.devise = "EUR";
						this.langue = "";
						this.unit = "metre";
						this.$store.dispatch("listeOffres").then(() => {
							this.$store.commit("setFreeze", {
								affichage: false,
								texte: "",
							});
							this.$router.push("/offer/" + records[0].id + "/" + records[0].fields.machine_name[0]);
						});
					}
				);
			}
		},
		handleLogOut() {
			this.$store.commit("disconnect");
			this.$router.push("/login");
		},
	},
	mounted() {},
};
</script>

<style lang="less">

.modale {
	position: fixed;
	top: 78px;
	left: 50%;
	width: 600px;
	transform: translateX(-50%);
	background: white;
	z-index: 1;
	box-shadow: 0 15px 100px rgba(black, 40%);
	border: solid 1px rgba(black, 7%);
	border-radius: 0 0 4px 4px;
	padding: 30px 60px 60px;
	display: none;
	flex-direction: column;
	align-items: flex-start;
	font-size: 14px;
	font-weight: normal;
	max-height: calc(~"100vh - 78px");
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	h1 {
		font-size: 24px;
		margin: 0 0 10px 0;
		color: #f18b00;
	}
	h2 {
		font-size: 16px;
		display: flex;
		align-items: center;
		i {
			font-size: 22px;
			padding-left: 14px;
			line-height: 22px;
			width: 40px;
		}
	}
	.line {
		display: block;
		width: 100%;
		position: relative;
		padding: 20px;
		button {
			background: #f18b00;
			border: none;
			padding: 12px 40px 12px 30px;
			border-radius: 10px;
			color: white;
			font-weight: bold;
			font-size: 14px;
			outline: none;
			cursor: pointer;
			border-bottom: solid 3px darken(#f18b00, 10%);
			i {
				padding-right: 10px;
				font-size: 1.2em;
				transform: translateY(2px);
			}
			&:hover {
				border-bottom-width: 1px;
				background: darken(#f18b00, 2%);
			}
		}
		label {
			display: block;
			font-weight: 500;
			padding: 0 0 10px 10px;
		}
		i {
			color: lighten(black, 80%);
			font-size: 20px;
			position: absolute;
			left: 35px;
			bottom: 27px;
		}
		input[type="text"],
		input[type="tel"],
		input[type="email"],
		select {
			border: none;
			outline: none;
			width: 100%;
			height: 40px;
			line-height: 40px;
			background: white;
			padding-left: 40px;
			border: solid 1px lighten(black, 80%);
			border-radius: 20px;
			font-size: 15px;
			font-weight: 500;
			appearance: none;
			color: lighten(black, 60%);
			transition: all 0.3s ease;
			&:focus {
				color: black;
				border-color: #f18b00;
				box-shadow: 0px 2px 10px fadeOut(#f18b00, 60%);
			}
		}
		.double {
			display: flex;
			align-items: center;
			.adhoc {
				color: lighten(black, 20%);
				text-decoration: underline;
				font-size: 12px;
				padding: 0 0 10px 10px;
			}
		}
		input::-webkit-calendar-picker-indicator {
			display: none !important;
		}
		datalist {
			&::-webkit-calendar-picker-indicator {
				display: none !important;
			}
		}
	}

	&.visible {
		display: flex;
		animation: appearFromTop 0.2s ease backwards;
	}
	.close {
		position: absolute;
		right: 40px;
		top: 35px;
		cursor: pointer;
	}

	&#nouveau_client {
		z-index: 99;
		width: 900px;
		.line {
			padding: 10px 0;
			i {
				left: 15px;
				top: 23px;
			}
		}
		#create_new_client {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			margin-top: 40px;
			.gauche {
				flex: 1;
				padding-right: 40px;
			}
			.droite {
				flex: 1;
				padding-left: 40px;
			}
		}
	}
	&#nouvelle_offre {
		z-index: 98;
	}
}
@keyframes appearFromTop {
	0% {
		transform: translate(-50%, -40%);
		opacity: 0;
	}
	100% {
		transform: translate(-50%, 0%);
		opacity: 1;
	}
}
</style>
