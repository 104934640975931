<template>
	<div id="page4" ref="page4">
		<div class="layout">
			<div class="part first">
				<div class="gauche"></div>
				<div class="droite">
					<h1>
						<span>2/</span>
						{{ $store.state.textes.page4.title }}
					</h1>

					<h2
						v-if="$store.state.offre_active.page2_intitule && $store.state.offre_active.page2_intitule != '-'"
						class="machine"
						v-html="$store.state.offre_active.page2_intitule"
					/>
					<h2 v-else class="machine">
						<span v-if="$store.state.offre_active.langue == 'fr'">{{ $store.state.offre_active.machine.display_name_fr }}</span>
						<span v-if="$store.state.offre_active.langue == 'en'">{{ $store.state.offre_active.machine.display_name_en }}</span>
						<span v-if="$store.state.offre_active.langue == 'us'">{{ $store.state.offre_active.machine.display_name_us }}</span>
						<span v-if="$store.state.offre_active.langue == 'ro'">{{ $store.state.offre_active.machine.display_name_ro }}</span>
						<span v-if="$store.state.offre_active.langue == 'de'">{{ $store.state.offre_active.machine.display_name_de }}</span>
						<span v-if="$store.state.offre_active.langue == 'pl'">{{ $store.state.offre_active.machine.display_name_pl }}</span>
						{{ $store.state.offre_active.machine.name }}
					</h2>
					<!-- <h2>{{ $store.state.offre_active.machine.name }}</h2> -->
				</div>
			</div>
			<div class="part">
				<div class="gauche">
					<h3>{{ $store.state.textes.page4.capacities }}</h3>
				</div>
				<div class="droite">
					<hr />
					<div
						class="line"
						@blur="handleChange"
						v-if="$store.state.offre_active.page4_useful_length"
						:contenteditable="contenteditable"
						data-field="page4_useful_length"
						v-html="$store.state.offre_active.page4_useful_length"
					/>
					<div class="line" @blur="handleChange" v-else data-field="page4_useful_length" :contenteditable="contenteditable">
						<div class="name">{{ $store.state.textes.page4.useful_length }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">in</span>
							<span v-else>mm</span>
						</div>
						<div class="value">{{ this.values.longueur_utile }}</div>
					</div>
					<div
						class="line"
						@blur="handleChange"
						v-if="$store.state.offre_active.page4_roll_diameter"
						:contenteditable="contenteditable"
						data-field="page4_roll_diameter"
						v-html="$store.state.offre_active.page4_roll_diameter"
					/>
					<div class="line" @blur="handleChange" v-else data-field="page4_roll_diameter" :contenteditable="contenteditable">
						<div class="name">{{ $store.state.textes.page4.roll_diameter }}</div>
						<div class="mesure"></div>
						<div class="value"></div>
					</div>
					<div
						class="line"
						@blur="handleChange"
						v-if="$store.state.offre_active.page4_roll_diameter_top"
						:contenteditable="contenteditable"
						data-field="page4_roll_diameter_top"
						v-html="$store.state.offre_active.page4_roll_diameter_top"
					/>
					<div class="line" @blur="handleChange" v-else data-field="page4_roll_diameter_top" :contenteditable="contenteditable">
						<div class="name simple">{{ $store.state.textes.page4.roll_diameter_top }}</div>
						<div class="mesure">
							<span v-if="$store.state.offre_active.unite == 'pouces'">in</span>
							<span v-else>mm</span>
						</div>
						<div class="value">{{ this.values.diametre_rouleau_superieur }}</div>
					</div>
					<div
						class="line"
						@blur="handleChange"
						v-if="$store.state.offre_active.page4_roll_diameter_croqueur"
						:contenteditable="contenteditable"
						data-field="page4_roll_diameter_croqueur"
						v-html="$store.state.offre_active.page4_roll_diameter_croqueur"
					/>
					<div class="line" @blur="handleChange" v-else data-field="page4_roll_diameter_croqueur" :contenteditable="contenteditable">
						<div class="name simple">{{ $store.state.textes.page4.roll_diameter_croqueur }}</div>
						<div class="mesure">
							<span v-if="$store.state.offre_active.unite == 'pouces'">in</span>
							<span v-else>mm</span>
						</div>
						<div class="value">{{ this.values.diametre_rouleur_croqueur }}</div>
					</div>
					<div
						class="line"
						@blur="handleChange"
						v-if="$store.state.offre_active.page4_roll_diameter_pinceur"
						:contenteditable="contenteditable"
						data-field="page4_roll_diameter_pinceur"
						v-html="$store.state.offre_active.page4_roll_diameter_pinceur"
					/>
					<div
						class="line"
						@blur="handleChange"
						v-if="!$store.state.offre_active.page4_roll_diameter_pinceur && $store.state.offre_active.machine.diametre_rouleau_pinceur"
						data-field="page4_roll_diameter_pinceur"
						:contenteditable="contenteditable"
					>
						<div class="name simple">{{ $store.state.textes.page4.roll_diameter_pinceur }}</div>
						<div class="mesure">
							<span v-if="$store.state.offre_active.unite == 'pouces'">in</span>
							<span v-else>mm</span>
						</div>
						<div class="value">{{ this.values.diametre_rouleau_pinceur }}</div>
					</div>
					<div
						class="line"
						@blur="handleChange"
						v-if="$store.state.offre_active.page4_for_steel"
						:contenteditable="contenteditable"
						data-field="page4_for_steel"
						v-html="$store.state.offre_active.page4_for_steel"
						style="padding-top: 20px"
					/>
					<div
						class="line"
						@blur="handleChange"
						v-else
						data-field="page4_for_steel"
						:contenteditable="contenteditable"
						style="padding-top: 20px"
					>
						<div class="name">{{ $store.state.textes.page4.for_steel }}</div>
						<div class="mesure"></div>
						<div class="value"></div>
					</div>
					<VueContext ref="menu">
						<li>
							<a href="#" @click.prevent="showEl($event.target)">Remove line</a>
						</li>
						<li>
							<a href="#" @click.prevent="showEl($event.target)">Remove Column</a>
						</li>
					</VueContext>
					<div
						class="tableau"
						@blur="handleChange"
						v-if="$store.state.offre_active.page4_capacity && $store.state.offre_active.page4_capacity != ''"
						:contenteditable="contenteditable"
						data-field="page4_capacity"
						v-html="$store.state.offre_active.page4_capacity"
					/>
					<div class="tableau" @blur="handleChange" v-else :contenteditable="contenteditable" data-field="page4_capacity">
						<p>
							{{ $store.state.textes.page4.maximum_capacity }}
							<span v-if="$store.state.offre_active.unite != 'pouces'">(mm)</span>
							<span v-else>(in)</span>
						</p>
						<div class="moderntab">
							<div class="line line1">
								<div class="cell">{{ $store.state.textes.page4.diameter }}</div>
								<div class="cell2 rang1">{{ $store.state.textes.page4.untroisx }}</div>
								<div class="cell2 rang2">{{ $store.state.textes.page4.cinqx }}</div>
							</div>
							<div class="line line2">
								<div class="cell">{{ $store.state.textes.page4.length }}</div>
								<div class="cell rang1">{{ $store.state.textes.page4.rolling }}</div>
								<div class="cell rang1">{{ $store.state.textes.page4.prebending }}</div>
								<div class="cell rang2">{{ $store.state.textes.page4.rolling }}</div>
								<div class="cell rang2">{{ $store.state.textes.page4.prebending }}</div>
							</div>

							<!-- 500 !-->
							<div class="line verifline" v-if="$store.state.offre_active.unite != 'pouces'">
								<div class="cell">500</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["5001DR"] }}</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["5001DC"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["5005DR"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["5005DC"] }}</div>
							</div>
							<div class="line verifline" v-else>
								<div class="cell">1.5 ft</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["5001DR"]
											? ($store.state.offre_active.machine["5001DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["5001DC"]
											? ($store.state.offre_active.machine["5001DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["5005DR"]
											? ($store.state.offre_active.machine["5005DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["5005DC"]
											? ($store.state.offre_active.machine["5005DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
							</div>

							<!-- 1000 !-->
							<div class="line verifline" v-if="$store.state.offre_active.unite != 'pouces'">
								<div class="cell">1000</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["10001DR"] }}</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["10001DC"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["10005DR"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["10005DC"] }}</div>
							</div>
							<div class="line verifline" v-else>
								<div class="cell">3 ft</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["10001DR"]
											? ($store.state.offre_active.machine["10001DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["10001DC"]
											? ($store.state.offre_active.machine["10001DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["10005DR"]
											? ($store.state.offre_active.machine["10005DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["10005DC"]
											? ($store.state.offre_active.machine["10005DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
							</div>

							<!-- 1500 !-->
							<div class="line verifline" v-if="$store.state.offre_active.unite != 'pouces'">
								<div class="cell" @dblclick="masked">1500</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["15001DR"] }}</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["15001DC"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["15005DR"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["15005DC"] }}</div>
							</div>
							<div class="line verifline" v-else>
								<div class="cell" @dblclick="masked">5 ft</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["15001DR"]
											? ($store.state.offre_active.machine["15001DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["15001DC"]
											? ($store.state.offre_active.machine["15001DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["15005DR"]
											? ($store.state.offre_active.machine["15005DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["15005DC"]
											? ($store.state.offre_active.machine["15005DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
							</div>

							<!-- 2000 !-->
							<div class="line verifline" v-if="$store.state.offre_active.unite != 'pouces'">
								<div class="cell" @dblclick="masked">2000</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["20001DR"] }}</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["20001DC"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["20005DR"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["20005DC"] }}</div>
							</div>
							<div class="line verifline" v-else>
								<div class="cell" @dblclick="masked">6,5 ft</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["20001DR"]
											? ($store.state.offre_active.machine["20001DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["20001DC"]
											? ($store.state.offre_active.machine["20001DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["20005DR"]
											? ($store.state.offre_active.machine["20005DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["20005DC"]
											? ($store.state.offre_active.machine["20005DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
							</div>

							<!-- 2500 !-->
							<div class="line verifline" v-if="$store.state.offre_active.unite != 'pouces'">
								<div class="cell">2500</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["25001DR"] }}</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["25001DC"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["25005DR"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["25005DC"] }}</div>
							</div>
							<div class="line verifline" v-else>
								<div class="cell">8 ft</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["25001DR"]
											? ($store.state.offre_active.machine["25001DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["25001DC"]
											? ($store.state.offre_active.machine["25001DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["25005DR"]
											? ($store.state.offre_active.machine["25005DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["25005DC"]
											? ($store.state.offre_active.machine["25005DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
							</div>

							<!-- 3000 !-->
							<div class="line verifline" v-if="$store.state.offre_active.unite != 'pouces'">
								<div class="cell">3000</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["30001DR"] }}</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["30001DC"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["30005DR"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["30005DC"] }}</div>
							</div>
							<div class="line verifline" v-else>
								<div class="cell">10 ft</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["30001DR"]
											? ($store.state.offre_active.machine["30001DR"] * 0.0394).toFixed(2)
											: ""
									}}
								</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["30001DC"]
											? ($store.state.offre_active.machine["30001DC"] * 0.0394).toFixed(2)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["30005DR"]
											? ($store.state.offre_active.machine["30005DR"] * 0.0394).toFixed(2)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["30005DC"]
											? ($store.state.offre_active.machine["30005DC"] * 0.0394).toFixed(2)
											: ""
									}}
								</div>
							</div>

							<!-- 4000 !-->
							<div class="line verifline" v-if="$store.state.offre_active.unite != 'pouces'">
								<div class="cell">4000</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["40001DR"] }}</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["40001DC"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["40005DR"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["40005DC"] }}</div>
							</div>
							<div class="line verifline" v-else>
								<div class="cell">13 ft</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["40001DR"]
											? ($store.state.offre_active.machine["40001DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["40001DC"]
											? ($store.state.offre_active.machine["40001DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["40005DR"]
											? ($store.state.offre_active.machine["40005DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["40005DC"]
											? ($store.state.offre_active.machine["40005DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
							</div>

							<!-- 5000 !-->
							<div class="line verifline" v-if="$store.state.offre_active.unite != 'pouces'">
								<div class="cell">5000</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["50001DR"] }}</div>
								<div class="cell rang1">{{ $store.state.offre_active.machine["50001DC"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["50005DR"] }}</div>
								<div class="cell rang2">{{ $store.state.offre_active.machine["50005DC"] }}</div>
							</div>
							<div class="line verifline" v-else>
								<div class="cell">16 ft</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["50001DR"]
											? ($store.state.offre_active.machine["50001DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang1">
									{{
										$store.state.offre_active.machine["50001DC"]
											? ($store.state.offre_active.machine["50001DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["50005DR"]
											? ($store.state.offre_active.machine["50005DR"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
								<div class="cell rang2">
									{{
										$store.state.offre_active.machine["50005DC"]
											? ($store.state.offre_active.machine["50005DC"] * 0.0394).toFixed(3)
											: ""
									}}
								</div>
							</div>
						</div>
					</div>

					<div id="second_capacities" v-if="$store.state.capacities2">
						<div
							class="line"
							@blur="handleChange"
							v-if="$store.state.offre_active.page4_capacities2_title"
							:contenteditable="contenteditable"
							data-field="page4_capacities2_title"
							v-html="$store.state.offre_active.page4_capacities2_title"
							style="padding-top: 20px"
						/>
						<div
							class="line"
							@blur="handleChange"
							v-else
							data-field="page4_capacities2_title"
							:contenteditable="contenteditable"
							style="padding-top: 20px"
							v-html="$store.state.textes.page4.capacities2_title"
						/>
						<p
							@blur="handleChange"
							v-if="$store.state.offre_active.page4_capacities2_sub"
							:contenteditable="contenteditable"
							data-field="page4_capacities2_sub"
							v-html="$store.state.offre_active.page4_capacities2_sub"
						/>
						<p
							@blur="handleChange"
							v-else
							data-field="page4_capacities2_sub"
							:contenteditable="contenteditable"
							v-html="$store.state.textes.page4.capacities2_sub"
						/>
						<div
							class="tableau"
							@blur="handleChange"
							v-if="$store.state.offre_active.page4_capacities2_tableau"
							:contenteditable="contenteditable"
							data-field="page4_capacities2_tableau"
							v-html="$store.state.offre_active.page4_capacities2_tableau"
						/>
						<div class="tableau" @blur="handleChange" v-else :contenteditable="contenteditable" data-field="page4_capacities2_tableau">
							<table cellspacing="0" cellpadding="0">
								<tr>
									<td>{{ $store.state.textes.page4.diameter }}</td>
									<td colspan="2">{{ $store.state.textes.page4.untroisx }}</td>
									<td colspan="2">{{ $store.state.textes.page4.cinqx }}</td>
								</tr>
								<tr>
									<td width="20%">{{ $store.state.textes.page4.length }}</td>
									<td width="20%">{{ $store.state.textes.page4.rolling }}</td>
									<td width="20%">{{ $store.state.textes.page4.prebending }}</td>
									<td width="20%">{{ $store.state.textes.page4.rolling }}</td>
									<td width="20%">{{ $store.state.textes.page4.prebending }}</td>
								</tr>
								<tr>
									<td>500</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
								</tr>
								<tr>
									<td>1000</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
								</tr>
								<tr>
									<td>1500</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
								</tr>
								<tr>
									<td>2000</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
								</tr>
								<tr>
									<td>2500</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
								</tr>
								<tr>
									<td>3000</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
								</tr>
								<tr>
									<td>4000</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="part">
				<div class="gauche">
					<h3>{{ $store.state.textes.page4.technical }}</h3>
				</div>
				<div
					class="droite"
					@blur="handleChange"
					v-if="$store.state.offre_active.page4_technical"
					:contenteditable="contenteditable"
					data-field="page4_technical"
					v-html="$store.state.offre_active.page4_technical"
				/>
				<div class="droite" @blur="handleChange" v-else :contenteditable="contenteditable" data-field="page4_technical">
					<hr />
					<div class="line" v-if="$store.state.offre_active.machine.couple_moteur_superieur && $store.state.offre_active.machine.couple_moteur_croqueurs && $store.state.offre_active.machine.couple_moteur_pinceur">
						<div class="name">{{ $store.state.textes.page4.couple_moteur }}</div>
						<div class="mesure"></div>
						<div class="value"></div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.couple_moteur_superieur">
						<div class="name simple">{{ $store.state.textes.page4.couple_moteur_superieur }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">ft.lbs</span>
							<span v-else>m.kg</span>
						</div>
						<div class="value">{{ values.couple_moteur_superieur }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.couple_moteur_croqueurs">
						<div class="name simple">{{ $store.state.textes.page4.couple_moteur_croqueurs }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">ft.lbs</span>
							<span v-else>m.kg</span>
						</div>
						<div class="value">{{ values.couple_moteur_croqueurs }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.couple_moteur_pinceur">
						<div class="name simple">{{ $store.state.textes.page4.couple_moteur_pinceur }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">ft.lbs</span>
							<span v-else>m.kg</span>
						</div>
						<div class="value">{{ values.couple_moteur_pinceur }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.force_vetrin">
						<div class="name">{{ $store.state.textes.page4.force_vetrin }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">Ton</span>
							<span v-else>T</span>
						</div>
						<div class="value">{{ values.force_vetrin }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.force_totale_machine">
						<div class="name">{{ $store.state.textes.page4.force_totale_machine }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">Ton</span>
							<span v-else>T</span>
						</div>
						<div class="value">{{ values.force_totale_machine }}</div>
					</div>
					<div class="sep"></div>
					<div class="line" v-if="$store.state.offre_active.machine.passage_rouleaux">
						<div class="name">{{ $store.state.textes.page4.passage_rouleaux }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">in</span>
							<span v-else>mm</span>
						</div>
						<div class="value">{{ values.passage_rouleaux }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.course_verins">
						<div class="name">{{ $store.state.textes.page4.course_verins }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">in</span>
							<span v-else>mm</span>
						</div>
						<div class="value">{{ values.course_verins }}</div>
					</div>
					<div class="sep"></div>
					<div class="line" v-if="$store.state.offre_active.machine.vitesse_rotation_rouleaux">
						<div class="name">{{ $store.state.textes.page4.vitesse_rotation_rouleaux }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">Rot/mn</span>
							<span v-else>rpm</span>
						</div>
						<div class="value">{{ values.vitesse_rotation_rouleaux }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.vitesse_deplacement">
						<div class="name">{{ $store.state.textes.page4.vitesse_deplacement }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">in/s</span>
							<span v-else>mm/s</span>
						</div>
						<div class="value">{{ values.vitesse_deplacement }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.hauteur_de_travail">
						<div class="name">{{ $store.state.textes.page4.hauteur_de_travail }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">in</span>
							<span v-else>mm</span>
						</div>
						<div class="value">{{ values.hauteur_de_travail }}</div>
					</div>
					<div class="sep"></div>
					<div class="line" v-if="$store.state.offre_active.machine.puissance_moteur_electrique">
						<div class="name">{{ $store.state.textes.page4.puissance_moteur_electrique }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">HP</span>
							<span v-else>Kw</span>
						</div>
						<div class="value">{{ values.puissance_moteur_electrique }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.pression_hydraulique">
						<div class="name">{{ $store.state.textes.page4.pression_hydraulique }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">PSI</span>
							<span v-else>Bars</span>
						</div>
						<div class="value">{{ values.pression_hydraulique }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.capacite_reservoir">
						<div class="name">{{ $store.state.textes.page4.capacite_reservoir }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">Gal</span>
							<span v-else>L</span>
						</div>
						<div class="value">{{ values.capacite_reservoir }}</div>
					</div>
				</div>
			</div>
			<div class="part">
				<div class="gauche">
					<h3>{{ $store.state.textes.page4.overall }}</h3>
				</div>
				<div class="droite">
					<hr />
					<div class="line" v-if="$store.state.offre_active.machine.longueur">
						<div class="name">{{ $store.state.textes.page4.length }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">in</span>
							<span v-else>mm</span>
						</div>
						<div class="value">{{ values.longueur }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.largeur">
						<div class="name">{{ $store.state.textes.page4.width }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">in</span>
							<span v-else>mm</span>
						</div>
						<div class="value">{{ values.largeur }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.hauteur">
						<div class="name">{{ $store.state.textes.page4.height }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">in</span>
							<span v-else>mm</span>
						</div>
						<div class="value">{{ values.hauteur }}</div>
					</div>
					<div class="line" v-if="$store.state.offre_active.machine.poids">
						<div class="name">{{ $store.state.textes.page4.weight }}</div>
						<div class="mesure">
							<span v-if="this.$store.state.offre_active.unite == 'pouces'">lbs</span>
							<span v-else>kg</span>
						</div>
						<div class="value">{{ values.poids }}</div>
					</div>
					<div class="subs" v-if="$store.state.textes.page4.dimensions_note">{{ $store.state.textes.page4.dimensions_note }}</div>
				</div>
			</div>
			<div class="logo-pied-de-page">
				<img src="../../assets/logofooter.png" />
			</div>
			<PiedDePage :numero="$store.state.ordre_des_pages.page4.count" />
		</div>
	</div>
</template>
<script>
import moment from "moment";
import Airtable from "airtable";
import { _ } from "underscore";
import VueContext from "vue-context";
const base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appsk0eykwa6zk25e");
export default {
	name: "page4",
	components: { VueContext },
	data() {
		return {
			contenteditable: false,
			values: {
				longueur_utile: this.$store.state.offre_active.machine.longueur_utile,
				diametre_rouleau_superieur: this.$store.state.offre_active.machine.diametre_rouleau_superieur,
				diametre_rouleur_croqueur: this.$store.state.offre_active.machine.diametre_rouleur_croqueur,
				diametre_rouleau_pinceur: this.$store.state.offre_active.machine.diametre_rouleau_pinceur,
				longueur: this.$store.state.offre_active.machine.longueur,
				largeur: this.$store.state.offre_active.machine.largeur,
				hauteur: this.$store.state.offre_active.machine.hauteur,
				poids: this.$store.state.offre_active.machine.poids,
				couple_moteur_superieur: this.$store.state.offre_active.machine.couple_moteur_superieur,
				couple_moteur_croqueurs: this.$store.state.offre_active.machine.couple_moteur_croqueurs,
				couple_moteur_pinceur: this.$store.state.offre_active.machine.couple_moteur_pinceur,
				force_vetrin: this.$store.state.offre_active.machine.force_vetrin,
				force_totale_machine: this.$store.state.offre_active.machine.force_totale_machine,
				passage_rouleaux: this.$store.state.offre_active.machine.passage_rouleaux,
				course_verins: this.$store.state.offre_active.machine.course_verins,
				vitesse_rotation_rouleaux: this.$store.state.offre_active.machine.vitesse_rotation_rouleaux,
				vitesse_deplacement: this.$store.state.offre_active.machine.vitesse_deplacement,
				hauteur_de_travail: this.$store.state.offre_active.machine.hauteur_de_travail,
				puissance_moteur_electrique: this.$store.state.offre_active.machine.puissance_moteur_electrique,
				capacite_reservoir: this.$store.state.offre_active.machine.capacite_reservoir,
				pression_hydraulique: this.$store.state.offre_active.machine.pression_hydraulique,
			},
		};
	},

	methods: {
		verifline() {
			var lines = document.querySelectorAll(".verifline");
			lines.forEach((line, index) => {
				let childrens = line.children;
				let casesvide = 0;
				let totalcase = childrens.length;
				for (let el in childrens) {
					if (el.innerHTML == "") {
						casesvide++;
					}
				}
				if (totalcase - casesvide == 1) {
					line.parentNode.removeChild(line);
				}
			});
			this.verifcolonne();
		},
		verifcolonne() {
			var firstline = document.querySelector(".verifline");
			var lines = [...document.querySelectorAll(".verifline")];
			var rang1 = document.querySelectorAll(".rang1");
			var rang2 = document.querySelectorAll(".rang2");
			var nb_lines = lines.length;
			var nb_colonnes = firstline.children.length;
			var cases_completes = nb_lines * 2;

			lines.forEach((line) => {
				let compteur = 4;
				if (line.children[1].innerText == "Nan" || line.children[1].innerText == "" || line.children[1].innerText == "-") {
					compteur--;
				}
				if (line.children[2].innerText == "Nan" || line.children[2].innerText == "" || line.children[2].innerText == "-") {
					compteur--;
				}
				if (line.children[3].innerText == "Nan" || line.children[3].innerText == "" || line.children[3].innerText == "-") {
					compteur--;
				}
				if (line.children[4].innerText == "Nan" || line.children[4].innerText == "" || line.children[4].innerText == "-") {
					compteur--;
				}
				if (compteur == 0) {
					line.remove();
				}
			});

			var second_tableau = [...document.querySelectorAll("#second_capacities table tr")];
			second_tableau.forEach((line) => {
				let childrens = [...line.children];
				let compteur = childrens.length - 1;
				childrens.forEach((child) => {
					if (child.innerHTML == "Nan" || child.innerHTML == "" || child.innerHTML == "-") {
						compteur--;
					}
				});
				if (compteur == 0) {
					line.remove();
				}
			});
		},
		handleChange(e) {
			if (this.$store.state.offre_active.statut != "draft") {
				alert("Sorry, you can't modify an issued offer.");
			} else {
				this.$store.commit("setFreeze", {
					affichage: true,
					texte: "Saving your data.<br /><b>Please Wait.</b>",
				});
				this.$store.state.offre_active[e.currentTarget.dataset.field] = e.currentTarget.innerHTML;
				let fields = {
					[e.currentTarget.dataset.field]: e.currentTarget.innerHTML,
				};
				base("offres").update(
					[
						{
							id: this.$store.state.offre_active.id,
							fields: fields,
						},
					],
					(err, records) => {
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					}
				);
			}
		},
		masked(e) {
			e.currentTarget.parentElement.style.display = "none";
		},
	},
	mounted() {
		if (this.$store.state.user.role.administrator) {
			this.contenteditable = true;
		}
		if (this.$store.state.offre_active.page4_capacities2_title && this.$store.state.offre_active.page4_capacities2_tableau) {
			this.$store.commit("setCapacities2", true);
		}
		if (this.$store.state.offre_active.unite == "pouces") {
			this.values.longueur_utile = (this.values.longueur_utile * 0.0394).toFixed(2);
			this.values.diametre_rouleau_superieur = (this.values.diametre_rouleau_superieur * 0.0394).toFixed(2);
			this.values.diametre_rouleur_croqueur = (this.values.diametre_rouleur_croqueur * 0.0394).toFixed(2);
			this.values.diametre_rouleau_pinceur = (this.values.diametre_rouleau_pinceur * 0.0394).toFixed(2);
			this.values.longueur = (this.values.longueur * 0.0394).toFixed(2);
			this.values.largeur = (this.values.largeur * 0.0394).toFixed(2);
			this.values.hauteur = (this.values.hauteur * 0.0394).toFixed(2);
			this.values.poids = (this.values.poids * 2.2046226218488).toFixed(2);
			this.values.couple_moteur_superieur = (this.values.couple_moteur_superieur * 7.23).toFixed(2);
			this.values.couple_moteur_croqueurs = (this.values.couple_moteur_croqueurs * 7.23).toFixed(2);
			this.values.couple_moteur_pinceur = (this.values.couple_moteur_pinceur * 7.23).toFixed(2);
			this.values.force_vetrin = (this.values.force_vetrin * 1.1023).toFixed(2);
			this.values.force_totale_machine = (this.values.force_totale_machine * 1.1023).toFixed(2);
			this.values.passage_rouleaux = (this.values.passage_rouleaux * 0.0394).toFixed(2);
			this.values.course_verins = (this.values.course_verins * 0.0394).toFixed(2);
			this.values.vitesse_rotation_rouleaux = (this.values.vitesse_rotation_rouleaux * 1).toFixed(2);
			this.values.vitesse_deplacement = (this.values.vitesse_deplacement * 0.0394).toFixed(2);
			this.values.hauteur_de_travail = (this.values.hauteur_de_travail * 0.0394).toFixed(2);
			this.values.puissance_moteur_electrique = (this.values.puissance_moteur_electrique * 1.34102209).toFixed(2);
			this.values.pression_hydraulique = (this.values.pression_hydraulique * 14.50377).toFixed(2);
			this.values.capacite_reservoir = (this.values.capacite_reservoir * 0.2642).toFixed(2);
		}
		setTimeout(() => {
			this.verifline();
		}, 4000);
	},
};
</script>

<style lang="less">
#page4 .layout {
	display: block;
	word-break: break-word;
	h1 {
		color: #f18b00;
		text-transform: uppercase;
		font-size: 30px;
		line-height: 34px;
		font-weight: bold;
		color: #f18b00;
		margin-bottom: 0px;
		span {
			color: black;
		}
	}
	h2 {
		color: lighten(black, 60%);
		text-transform: uppercase;
		font-size: 30px;
		line-height: 34px;
		font-weight: bold;
		margin-bottom: 0px;
		margin-top: 0;
	}
	h3 {
		position: relative;
		font-weight: bold;
		color: black;
		font-size: 30px;
		display: flex;
		flex-direction: column;
		margin: 0;
		&:before {
			height: 10px;
			width: 55px;
			background: #f18b00;
			border: none;
			content: "";
			margin-bottom: 8px;
		}
	}
	.part {
		display: flex;
		margin-bottom: 40px;
		&.first {
			margin-bottom: 50px;
		}
		.gauche {
			flex: 0 0 300px;
		}
		.droite {
			flex: 1;
			padding-left: 80px;
			hr {
				border: none;
				height: 2px;
				background: #f18b00;
				display: block;
				margin-bottom: 10px;
			}
			.line {
				display: flex;
				align-items: flex-end;
				margin-top: 5px;
				.name {
					flex: 3;
					font-weight: bold;
					&:before {
						content: ">";
						color: #f18b00;
						font-weight: bold;
						margin-right: 5px;
					}
					&.simple {
						font-weight: normal;
						text-align: left;
						padding-left: 40px;
						margin-top: 1px;
						&:before {
							content: none;
						}
					}
				}
				.mesure {
					flex: 0 0 60px;
					text-align: right;
				}
				.value {
					flex: 0 0 100px;
					text-align: right;
					color: #f18b00;
					font-weight: bold;
					font-size: 1.1em;
				}
			}
			.subs {
				font-size: 12px;
				font-style: italic;
				padding: 20px 0;
				text-align: right;
				color: lighten(black, 50%);
				max-width: 60%;
				margin: 0 0 0 auto;
				display: block;
			}
			.tableau {
				p {
					font-size: 14px;
					margin: 0;
					padding: 5px 0 20px 0;
				}
				.moderntab {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					.line {
						display: flex;
						flex-wrap: nowrap;
						border-bottom: solid 2px #f18b00;
						border-right: solid 2px #f18b00;
						margin: 0;
						padding: 0;
						align-items: stretch;
						&.line2 {
							.cell {
								color: lighten(black, 50%);
								font-size: 0.9em;
							}
						}
						&.line1 {
							border-top: solid 2px #f18b00;
							.cell,
							.cell2 {
								text-align: center;
								font-weight: bold;
								text-transform: uppercase;
								font-size: 13px;
								border-left: solid 2px #f18b00;
								padding: 3px;
							}
						}
						.cell {
							width: 100px;
							border-left: solid 2px #f18b00;
							padding: 3px;
							text-align: center;
							font-weight: 500;
							font-size: 13px;
							&.hidden {
								display: none;
								flex: 0 0 0px;
							}
						}
						.cell2 {
							width: 200px;
							padding: 3px;
							&.hidden {
								display: none;
								flex: 0 0 0px;
							}
						}
					}
				}
			}
			#second_capacities {
				.line {
					font-weight: bold;
					&:before {
						content: ">";
						color: #f18b00;
						font-weight: bold;
						margin-right: 5px;
					}
				}
				p {
					font-size: 14px;
					margin: 0;
					padding: 5px 0 20px 0;
				}
				table {
					border-top: solid 2px #f18b00;
					border-left: solid 2px #f18b00;
					width: 500px;
					font-size: 13px;

					tr {
						td {
							border-right: solid 2px #f18b00;
							border-bottom: solid 2px #f18b00;
							margin: 0;
							font-size: 13px;
							padding: 3px;
							text-align: center;
							font-weight: 500;
						}
						&:nth-child(1) {
							td {
								text-align: center;
								font-weight: bold;
								text-transform: uppercase;
							}
						}
						&:nth-child(2) {
							td {
								text-align: center;
								font-weight: 500;
								text-transform: none;
								color: #808080;
								font-size: 0.9em;
								font-size: 13px;
							}
						}
					}
				}
			}
		}
	}
	ul.v-context {
		position: fixed;
		background: white;
		padding: 0;
		outline: none;
		border-radius: 10px;
		list-style: none;
		box-shadow: -4px -4px 7px 0 rgba(255, 255, 255, 0.02), -5px -13px 25px 0 rgba(246, 251, 255, 0.88), -5px 0 5px 0 rgba(244, 248, 251, 0.5),
			5px 1px 22px 0 #c7d8e5;
		li {
			padding: 10px 20px;
			border-bottom: solid 1px fadeOut(#c7d8e5, 50%);
			font-size: 14px;
			font-weight: 500;
			width: 200px;
			&:first-child {
				padding-top: 20px;
			}
			&:last-child {
				border-bottom: 0px;
				padding-bottom: 20px;
			}
			&:hover {
				background: fadeOut(#c7d8e5, 80%);
			}
		}
	}
}
</style>
